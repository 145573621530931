










import Vue from 'vue';
import { ApolloQueryResult } from 'apollo-client';
import { FAQ } from '@/domain/faqs/queries/query';
import { FaqConnection } from '@/domain/faqs/model/Faq';

export default Vue.extend({
  name: 'HostNoticeDetail',
  props: {
    id: String,
    forHostOnly: String,
    question: String,
    answer: String,
  },
  data() {
    return {
      faqId: this.id,
      form: {
        question: this.question,
        answer: this.answer,
      },
    };
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  apollo: {
    form: {
      query: FAQ,
      variables() {
        return {
          filter: {
            id: this.$data.faqId,
            forHostOnly: true,
          },
          page: 1,
          size: 1,
        };
      },
      result(result: ApolloQueryResult<{ board: { faqs: FaqConnection } }>) {
        this.$data.form.question =
          result.data.board.faqs.edges[0].node.question;
        this.$data.form.answer = result.data.board.faqs.edges[0].node.answer;
      },
      update: data => {
        return data.board.faq.edges[0].node;
      },
      skip(): boolean {
        return this.$data.form.question !== undefined;
      },
    },
  },
});
